const logotext = "LAUDE";
const meta = {
    title: "Laude Kibra",
    description: "I’m Laude Kibra, Fullstack Web Developer",
};

const introdata = {
    title: "I'm Laude Kibra",
    animated: {
        first: "Passionate about coding",
        second: "Fullstack Web Developer",
    },
    description: "I specialize in building dynamic, responsive web applications with a focus on user experience and scalability. With a solid foundation in the MERN stack, I create solutions that are both efficient and innovative. Let’s collaborate to bring your digital vision to life.",
    your_img_url: "https://images.unsplash.com/photo-1727534877493-f16706fb6bb0",
};

const dataabout = {
    title: "About Myself",
    aboutme: "Hi, I’m Laude, a MERN fullstack developer who recently completed a web development bootcamp at Uplift Code Camp. I’ve gained expertise in MongoDB, Express, React, and Node.js, building fullstack applications that are dynamic, responsive, and user-friendly. My projects have allowed me to tackle real-world challenges, and I’m excited to bring my skills into creating impactful digital solutions.",
};
const worktimeline = [{
        jobtitle: "Technology Experience Specialist",
        where: "Netflix",
        date: "2022-Present",
    },
    {
        jobtitle: "Quality Assurance Specialist",
        where: "Google Operations Center",
        date: "2020-2022",
    },
    {
        jobtitle: "IT Operations Senior Analyst",
        where: "Accenture",
        date: "2015-2019",
    },
];

const skills = [{
        name: "MongoDB",
        value: 60,
    },
    {
        name: "Express",
        value: 85,
    },
    {
        name: "React",
        value: 90,
    },
    {
        name: "Node.js",
        value: 80,
    },
];

const services = [{
        title: "UI & UX Design",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
    {
        title: "Mobile Apps",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
    {
        title: "Wordpress Design",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
];

const dataportfolio = [{
        img: "https://live.staticflickr.com/65535/54066236121_95bf2aaaa8_o.jpg",
        description: "Gatherly: A Family and Group Organizer Fullstack App.",
        link: "https://gatherly-app.netlify.app/",
        github: "https://github.com/laudekibra007/my-projects/tree/gatherly-app-fs-compile-1/gatherly-app-fs-compile-1",
    },
    {
        img: "https://live.staticflickr.com/65535/54073780494_05f81abcc4_o.png",
        description: "PokeDex: A React-based app with real-time data and a sleek, user-friendly interface ",
        link: "https://react-pokedex-batch21.netlify.app/",
        github: "https://github.com/laudekibra007/my-projects/tree/act-pokedex/act-pokedex",
    },
    {
        img: "https://live.staticflickr.com/65535/54072617737_13a26ccac5_o.png",
        description: "CineExplorer: A React-based app for discovering and exploring movies with a sleek, intuitive interface",
        link: "https://cineexplorer-batch21.netlify.app/",
        github: "https://github.com/laudekibra007/my-projects/tree/p3-react-app/p3-react-app",
    },
    {
        img: "https://live.staticflickr.com/65535/53642752205_1ab75ac9bd_o.jpg",
        description: "Let's get Quizzical: My first JavaScript app from bootcamp. An interactive trivia game with fun questions and a sleek design.",
        link: "https://quizzical-wdl2.netlify.app/",
        github: "https://github.com/laudekibra007/my-projects/tree/js-api-app/js-api-app",
    },
    {
        img: "https://live.staticflickr.com/65535/54073669996_fb98cb617d_o.png",
        description: "My first attempt at building a portfolio with HTML5 and CSS.",
        link: "https://firstportfolio-wdl2.netlify.app/",
        github: "https://github.com/laudekibra007/my-projects/tree/p1-web-dev-portfolio/p1-web-dev-portfolio",
    },
];

const contactConfig = {
    YOUR_EMAIL: "laudekibra007@gmail.com",
    YOUR_FONE: "+63-927-932-2278",
    description: "Reach out for any inquiries or collaboration ideas. Always happy to connect and discuss new projects!",
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_ndv6411",
    YOUR_TEMPLATE_ID: "template_spzwn81",
    YOUR_USER_ID: "gjwwWc6JI7QCqWfG0",
};

const socialprofils = {
    github: "https://gitlab.com/laudekibra007",
    linkedin: "https://www.linkedin.com/in/laudekibra/",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};